@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

body {
  padding-bottom: 0;
}
a.active {
  color: #fa7fb9;
}
